import React from "react"
import PropTypes from "prop-types"
import ArticleMarkdownContent from "@components/common/ArticleMarkdownContent"

import "./style.scss"

const InfoFlexContent = props => {
  return (
    <div className="info-flex-content">
      {!!props.modules &&
        props.modules.map((module, index) => {
          if (
            module.fieldGroupName.includes("_Content") &&
            !!module.pageContent
          ) {
            return (
              <div key={`info-flex-content--${index}`} className="page-wrap">
                <ArticleMarkdownContent
                  className="info-flex-content__spacing info-flex-content__text"
                  content={module.pageContent}
                />
              </div>
            )
          } else {
            return null
          }
        })}
    </div>
  )
}

InfoFlexContent.propTypes = {
  modules: PropTypes.array,
}
InfoFlexContent.defaultProps = {
  modules: [],
}

export default InfoFlexContent
