import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "@components/layout"
import SEO from "@components/seo"
import { filterPageForTemplate } from "@templates/utilities/templateUtils"
import InfoFlexContent from "@components/common/InfoFlexContent"
import SimpleBreadcrumbHero from "@components/common/SimpleBreadcrumbHero"

export default props => {
  const query = useStaticQuery(graphql`
    query InfoPageQuery {
      allWpPage(filter: { acfPageMeta: { pageTemplate: { eq: "info" } } }) {
        edges {
          node {
            id
            uri
            acfPageMeta {
              metaImage {
                altText
                sourceUrl
              }
              metaDescription
              metaTitle
            }
            acfInfoPage {
              infoContent {
                ... on WpPage_Acfinfopage_InfoContent_Content {
                  fieldGroupName
                  pageContent
                }
              }
            }
          }
        }
      }
    }
  `)

  const page = filterPageForTemplate(
    query.allWpPage.edges,
    props.pageContext.wordpressUri
  )

  return (
    <Layout>
      <SEO title={page.acfPageMeta.metaTitle} />
      <SimpleBreadcrumbHero pageTitle={page.acfPageMeta.metaTitle} />
      <InfoFlexContent modules={page.acfInfoPage.infoContent} />
    </Layout>
  )
}
